var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.isLoading)?_c('LoadingScreen'):_vm._e(),_c('div',{attrs:{"id":"content","attr-sub":_vm.$t('brandtech')}},[(this.hero.background)?_c('div',{staticClass:"wrapper"},[_c('section',{staticClass:"title",style:({ 'background-image': 'url(' + this.hero.background + ')' })},[_c('hgroup',[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.hero.title)}}),_c('h2',{domProps:{"innerHTML":_vm._s(_vm.hero.subtitle)}})])]),_c('section',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}],staticClass:"video-player",attrs:{"id":"brandtech-video"}},[_c('div',{staticClass:"video"},[_c('vimeo-player',{ref:"player",attrs:{"video-id":this.videoID,"player-width":1200,"options":{ responsive: true, title: 0, byline: 0, portrait: 0 }},on:{"~play":function($event){return _vm.onPlay.apply(null, arguments)},"ended":_vm.onEnded}})],1)]),_c('section',{staticClass:"intro"},[_c('div',[_c('h3',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}],domProps:{"innerHTML":_vm._s(_vm.introduction.title)}}),_c('p',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}],domProps:{"innerHTML":_vm._s(_vm.introduction.content)}})])]),_vm._l((this.key_points),function(point,itemObjKey){return [_c('section',{key:itemObjKey,staticClass:"expert desktop",style:({ 'background-image': 'url(' + point.background + ')' })},[_c('div',{staticClass:"wrapper"},[_c('h2',{domProps:{"innerHTML":_vm._s(point.title)}}),_c('p',{domProps:{"innerHTML":_vm._s(point.content)}})])]),_c('section',{key:'mobile-' + itemObjKey,staticClass:"expert mobile",style:({
            'background-image':
              'url(' +
              point.background
                .replace('.png', '_mob.png')
                .replace('.jpg', '_mob.jpg')
                .replace('.gif', '_mob.gif') +
              ')',
          })},[_c('div',{staticClass:"wrapper"},[_c('h2',{domProps:{"innerHTML":_vm._s(point.title)}}),_c('p',{domProps:{"innerHTML":_vm._s(point.content)}})])])]}),_c('section',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}],staticClass:"awardsSection"},[_c('h2',[_vm._v(_vm._s(_vm.$t('awards')))]),_c('div',{staticClass:"wrapper"},[(_vm.awards.background)?_c('div',{staticClass:"awards left",style:({ 'background-image': 'url(' + _vm.awards.background + ')' })}):_vm._e(),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}],staticClass:"awards"},[_vm._l((_vm.awards.award),function(award){return [_c('div',{key:award.id,staticClass:"entry"},[(award.year)?_c('h3',[_vm._v(_vm._s(award.year))]):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(award.description)}}),_c('span',{domProps:{"innerHTML":_vm._s(award.name)}}),_c('hr')]),_c('div',{key:award.id,staticClass:"mobile-separator"},[_c('hr',{staticClass:"white"}),_c('hr')])]})],2)])]),_c('section',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal"}],staticClass:"clients"},[_c('h2',[_vm._v(_vm._s(_vm.$t('our_clients')))]),_vm._m(0),_c('h5',[_vm._v(_vm._s(_vm.$t('and_many_more')))])])],2):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logos"},[_c('img',{staticClass:"desktop",attrs:{"src":require("../../public/img/clients_logo.svg"),"alt":"clients"}}),_c('img',{staticClass:"mobile",attrs:{"src":require("../../public/img/clients_logo_mobile.svg"),"alt":"clients"}})])
}]

export { render, staticRenderFns }