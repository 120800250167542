<template>
  <div>
    <footer>
      <div class="wrapper">
        <div class="link-wrapper left">
          <p>
            <router-link :to="{ name: 'contact' }">{{
              $t('contact_us')
            }}</router-link>
          </p>
        </div>

        <svg
          width="37"
          height="37"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <use
            xlink:href="#path0_fill"
            transform="translate(15.9 20.1)"
            fill="#E43852"
          />
          <use
            xlink:href="#path1_fill"
            transform="translate(.5 15.9)"
            fill="#E43852"
          />
          <use
            xlink:href="#path2_fill"
            transform="translate(12.8)"
            fill="#E43852"
          />
          <use
            xlink:href="#path3_fill"
            transform="translate(20 12.6)"
            fill="#E43852"
          />
          <defs>
            <path
              id="path0_fill"
              d="M8.28 16.73c.131-.226.043-.587-.353-1.083C3.08 9.56 1.055 4.374 1.892 0-.09 3.021-.487 6.223.57 9.605c1.102 3.427 3.304 5.772 6.696 7.17.529.18.88.18 1.013-.045z"
            />
            <path
              id="path1_fill"
              d="M16.546 1.937C13.596-.093 10.468-.498 7.164.584 3.816 1.711 1.482 4.011.16 7.438c-.22.586-.22.902.044 1.037.22.136.573.045 1.057-.36 5.903-4.96 11.013-7.035 15.285-6.178z"
            />
            <path
              id="path2_fill"
              d="M1.07.164C.498-.062.19-.062.057.209-.075.434.013.795.41 1.29 5.255 7.334 7.28 12.565 6.444 16.94c1.983-3.022 2.38-6.223 1.322-9.605C6.62 3.907 4.418 1.517 1.07.164z"
            />
            <path
              id="path3_fill"
              d="M16.386 1.096c.22-.586.22-.902-.044-1.037-.22-.136-.572-.045-1.057.36C9.382 5.38 4.273 7.454 0 6.597c2.951 2.03 6.079 2.435 9.382 1.353 3.348-1.127 5.683-3.427 7.004-6.854z"
            />
          </defs>
        </svg>

        <div class="link-wrapper">
          <p class="left">
            <router-link :to="{ name: 'join_us' }">{{
              $t('work_with_us')
            }}</router-link>
          </p>
        </div>
      </div>

      <div class="social">
        <a href="//facebook.com/Asiance/" target="_blank">
          <svg
            width="11"
            height="22"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Canvas" transform="translate(30699 -24289)">
              <g id="iconmonstr-facebook-1 (1)">
                <use
                  xlink:href="#facebook0_fill"
                  transform="translate(-30699 24289)"
                  fill="#67717B"
                  id="Vector"
                />
              </g>
            </g>
            <defs>
              <path
                id="facebook0_fill"
                d="M2.75 7.333H0V11h2.75v11h4.583V11h3.339L11 7.333H7.333V5.805c0-.875.176-1.222 1.022-1.222H11V0H7.51C4.212 0 2.75 1.451 2.75 4.23v3.103z"
              />
            </defs>
          </svg>
        </a>
        <a href="//instagram.com/asiance_now/" target="_blank">
          <svg
            width="22"
            height="22"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Canvas" transform="translate(30665 -24289)">
              <g id="iconmonstr-instagram-1 (1)">
                <use
                  xlink:href="#insta0_fill"
                  transform="translate(-30665 24289)"
                  fill="#67717B"
                  id="Vector"
                />
              </g>
            </g>
            <defs>
              <path
                id="insta0_fill"
                d="M10.928 15.414c2.367 0 4.291-1.935 4.291-4.315A4.319 4.319 0 0 0 12.7 7.17a4.238 4.238 0 0 0-3.54 0 4.33 4.33 0 0 0-2.522 3.93c0 2.379 1.926 4.314 4.29 4.314zm.014-7.502a3.18 3.18 0 0 1 3.173 3.188c0 1.76-1.42 3.189-3.172 3.189a3.18 3.18 0 0 1-3.173-3.19 3.18 3.18 0 0 1 3.173-3.187zm4.763.338h6.18v9.422c0 2.39-1.928 4.328-4.306 4.328H4.363C1.985 22 0 20.062 0 17.672V8.25h6.152a5.566 5.566 0 0 0-.795 2.85c0 3.093 2.494 5.6 5.57 5.6 3.078 0 5.572-2.507 5.572-5.6a5.565 5.565 0 0 0-.794-2.85zM17.58 0H6.383v4.583h-.912V0H4.56v4.583h-.911V.071a4.35 4.35 0 0 0-.912.247v4.265h-.912V.823C.728 1.61 0 2.882 0 4.33v3.004h6.814A5.537 5.537 0 0 1 10.928 5.5c1.57 0 3.056.665 4.113 1.833h6.844V4.328C21.885 1.938 19.957 0 17.58 0zm2.482 4.826a.676.676 0 0 1-.679.674h-2.29a.676.676 0 0 1-.678-.674V2.507c0-.372.303-.674.678-.674h2.29c.376 0 .68.302.68.674v2.32z"
              />
            </defs>
          </svg>
        </a>
        <a :href="this.blogURL" target="_blank">
          <svg width="23" height="22" xmlns="http://www.w3.org/2000/svg">
            <g id="Canvas" fill="none">
              <g id="icon-footer-blog">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 0a2 2 0 0 0-2 2v15.216a2 2 0 0 0 2 2h5.619L12.622 14H4.5a.5.5 0 1 1 0-1h9.082l1.918-2h-11a.5.5 0 1 1 0-1h11a.5.5 0 0 1 .5.476l4.3-4.487V2a2 2 0 0 0-2-2H2zm13.5 7a.5.5 0 1 1 0 1h-11a.5.5 0 1 1 0-1h11zm0-3a.5.5 0 1 1 0 1h-11a.5.5 0 1 1 0-1h11z"
                  transform="translate(.5)"
                  fill="#67717B"
                  id="Subtract"
                />
                <path
                  d="M6.786 0L9.39 2.565a3.97 3.97 0 0 1-.134.142 2595.751 2595.751 0 0 1-5.604 5.549.882.882 0 0 1-.349.211c-.908.275-1.82.539-2.735.799a.655.655 0 0 1-.324.017.341.341 0 0 1-.242-.291.336.336 0 0 1 .013-.132c.283-.986.572-1.964.865-2.935a.473.473 0 0 1 .124-.188C2.33 4.41 5.416 1.342 6.744.027A.13.13 0 0 1 6.785 0zM3.052 7.772c-.076-.404-.149-.79-.227-1.173a.169.169 0 0 0-.096-.114c-.39-.087-.783-.165-1.183-.245-.144.49-.27.963-.425 1.426a.42.42 0 0 0 .142.463.311.311 0 0 0 .392.057l1.397-.414z"
                  transform="translate(11.5 12.705)"
                  fill="#67717B"
                  id="Vector"
                />
                <path
                  d="M2.459 3.71L0 1.3C.364.915.704.514 1.091.167A.793.793 0 0 1 2.127.22c.464.435.917.883 1.353 1.346a.842.842 0 0 1 0 1.133 2.006 2.006 0 0 1-.142.152l-.879.86z"
                  transform="translate(19.234 10.607)"
                  fill="#67717B"
                  id="Vector_2"
                />
              </g>
            </g>
          </svg>
        </a>
        <a href="//linkedin.com/company/asiance/" target="_blank">
          <svg
            width="23"
            height="22"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Canvas" transform="translate(30619 -24289)">
              <g id="iconmonstr-linkedin-1">
                <use
                  xlink:href="#linkedin0_fill"
                  transform="translate(-30619 24289)"
                  fill="#67717B"
                  id="Vector"
                />
              </g>
            </g>
            <defs>
              <path
                id="linkedin0_fill"
                d="M4.772 2.391a2.384 2.384 0 0 1-2.376 2.392A2.384 2.384 0 0 1 .019 2.39 2.384 2.384 0 0 1 2.396 0a2.384 2.384 0 0 1 2.377 2.391zm.02 4.305H0V22h4.792V6.696zm7.65 0H7.68V22h4.762v-8.034c0-4.467 5.778-4.832 5.778 0V22H23v-9.69c0-7.538-8.55-7.263-10.559-3.553V6.696z"
              />
            </defs>
          </svg>
        </a>
      </div>
      <p class="credits">
        © 2004 - {{ yearStamp }} Asiance. All rights reserved.
        <router-link :to="{ name: 'privacy-policy' }">{{
          $t('privacy_policy')
        }}</router-link
        >.
        <span
          class="businessRegistration"
          v-html="$t('business_registration')"
        ></span>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      blogURL: 'http://blog.asiance.com/',
      yearStamp: '',
    };
  },
  created() {
    this.yearStamp = new Date().getFullYear();
  },
  mounted: function () {
    if (this.$i18n.locale === 'ko') {
      this.blogURL = '//asiance.tistory.com/';
    }
  },
  methods: {
    updateBlogURL(lang) {
      this.blogURL =
        lang === 'ko' ? '//asiance.tistory.com/' : 'http://blog.asiance.com/';
    },
  },
};
</script>

<style lang="stylus" scoped>
.loading footer {
  display: none;
}

footer {
  width: calc((1320vw / 14.4));
  margin: auto;
  background: #FFF;
  height: 30%;
  text-align: center;
  padding: 38px 0;
  clear: both;

  .wrapper {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .link-wrapper {
    min-width: 93px;
    display: inline-block;
  }

  p {
    float: left;
    display: inline-block;
  }

  .link-wrapper.left {
    p {
      float: right;
    }
  }

  svg {
    margin: 0 35px;
  }

  .social {
    margin-top: 46px;

    svg {
      margin: 0 10px;

      use {
        transition: fill 0.3s linear;
      }

      &:hover {
        use, path {
          fill: #C43E51;
        }
      }
    }
  }


  .credits {
    font-size: 12px;
    margin: auto;
    margin-top: 55px;
    margin-bottom: 55px;
    display: block;
    color: #6C7A87;
    text-align: center;
    width: 100%;
    a {
      color: #6C7A87;
    }
  }

  a {
    color: #3B434D;

    span {
      display: block;
      width: 0;
      margin: 2px auto auto auto;
      border-bottom: 1px solid #000;
      transition: width 0.3s cubic-bezier(0.22, 0.44, 0, 1);
    }

    &:hover {
      span {
        width: 100%;
      }
    }
  }

  .businessRegistration{
    display: block;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 1024px) {
  footer {
    p {
      width: auto;
    }
  }
}
</style>
