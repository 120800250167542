import { render, staticRenderFns } from "./Case_bottega.vue?vue&type=template&id=d77d8510&scoped=true&"
import script from "./Case_bottega.vue?vue&type=script&lang=js&"
export * from "./Case_bottega.vue?vue&type=script&lang=js&"
import style0 from "./Case_bottega.vue?vue&type=style&index=0&id=d77d8510&prod&lang=stylus&scoped=true&"
import style1 from "./Case_bottega.vue?vue&type=style&index=1&id=d77d8510&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d77d8510",
  null
  
)

export default component.exports