<template>
    <div>
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content">
    <div v-if="this.acf.hero">
        <div class="hero" :style="{ 'background-image': 'url(' + this.acf.hero.background_image + ')' }">
            <hgroup>
                <h1>{{ this.item.title.rendered }}</h1>
                <h2 v-html="this.acf.hero.subtitle"></h2>
                <h3 v-html="this.acf.hero.content"></h3>
            </hgroup>
            <div class="separator">
                <span></span>
                <span class="black"></span>
            </div>
        </div>

        <section class="intro">
            <div>
                <h3 v-scroll-reveal>{{ this.acf.introduction.title }}</h3>
                <h4 v-html="this.acf.introduction.short_description" v-scroll-reveal></h4>
                <p v-html="this.acf.introduction.content" v-scroll-reveal></p>
            </div>
        </section>

        <section class="details">

            <div class="highlight">
                <div class="entry">
                    <img :src="this.acf.poster[0].image" alt="" v-scroll-reveal>
                </div>
                <div class="wrapper">
                    <div class="entry">
                        <img :src="this.acf.poster[1].image" alt="" v-scroll-reveal>
                    </div>
                    <div class="entry">
                        <img :src="this.acf.poster[2].image" alt="" v-scroll-reveal>
                    </div>
                </div>
            </div>

            <div class="sub-highlight">
                <div class="entry">
                    <p v-html="this.acf.screen.content_1"></p>
                    <div class="visual"><img :src="this.acf.large_screen" alt="" v-scroll-reveal></div>
                </div>
                <div class="entry">
                    <div class="visual"><img :src="this.acf.screen.image" alt="" v-scroll-reveal></div>
                        <p v-html="this.acf.screen.content_2" v-scroll-reveal></p>
                </div>
                <div class="visual-bag"><img src="../../public/img/cases/bulgari/bag.jpg" alt="Bulgari" v-scroll-reveal></div>
            </div>
        </section>

        <section class="footer">
           <p v-html="this.acf.footer" v-scroll-reveal></p>
        </section>
    </div>
    </div>
    <project-navigator ref="projectNavigator" :projectId="this.item.id" v-if="this.item.id"></project-navigator>
    </div>
</template>

<script>
import PostSlugService from "../services/PostSlugService";
import ProjectNavigator from "../components/ProjectNavigator.vue";

const LoadingScreen = () =>
  import(/* webpackChunkName: "below-fold" */ "../components/LoadingScreen.vue");

export default {
  components: { LoadingScreen, ProjectNavigator },

  data() {
    return {
      isLoading: true,
      item: {},
      acf: {}
    };
  },

  created() {
    this.fetchItem(this.$i18n.locale);
  },

  methods: {
    fetchItem(lang) {
      return PostSlugService.get(lang, "bvlgari")
        .then(result => {
          this.item = result.body[0];
          this.acf = result.body[0].acf;
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
          // eslint-disable-next-line no-undef
          this.$refs.projectNavigator.projectId = id;
        })
        .catch(() => {
          this.error = true;
        });
    }
  },

  mounted: function() {
    if ($("body").css("overflow") == "hidden") {
      $("#fullpage").fullpage.destroy("all");
    }

    $(".subtitle:first").hide();
    $("header").removeClass();
    $("header:first").addClass("fixed");

    setTimeout(() => {
        $(".hero").paroller({
            factor: 0.3,            // multiplier for scrolling speed and offset
            type: 'background',     // background, foreground
            direction: 'vertical' // vertical, horizontal
        });

    }, 1000);

  }
};
</script>

<style lang="stylus" scoped>


.hero {
    display: flex;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-image: url('../../public/img/cases/bulgari/bgd_case_bulgari.jpg');
    justify-content: center;
    text-align: center;

    hgroup {
        color: #fff;
        max-width: 950px;
    }

    h1 {
        margin: 0 0 40px 0;
        font-size: 80px;
        text-align: center;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    h2 {
        font-family: futura-pt;
        font-size: 16px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    h3 {
        margin: 100px 0 0 0;
        line-height: 52px;
        font-size: 40px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .separator {
        transform: rotate(-90deg);
        position: absolute;
        bottom: 0;
        width: 60px;
        left: 0;
        right: 0;
        margin: auto;

        span {
            float: right;
            height: 1px;
            width: 29px;
            background: #fff;
        }

        span.black {
            background: #2A3642;
        }
    }
}

.intro {
    div {
        background: #FFF;
        text-align: center;
        width: 100%;
        padding-top: 62px;

        h3 {
            font-family: futura-pt, 'Noto Sans KR';
            line-height: 30px;
            font-size: 20px;
            letter-spacing: 0.3em;
            text-transform: uppercase;
            max-width: 415px;
            margin: auto;
            letter-spacing: 0.3em;
            font-weight: normal;
        }

        h4 {
            margin-top: 35px;
            font-family: futura-pt;
            line-height: normal;
            font-size: 26px;
            text-align: center;
            font-weight: normal;
        }

        p {
            max-width: 741px;
            font-size: 18px;
            line-height: 23px;
            margin: 32px auto 40px auto;
        }
    }
}

.highlight {
    position: relative;

    // height: calc(960vw/14.4)
    .entry {
        width: calc((1320vw / 14.4));
        // height: calc(500vw/14.4)
    }

    .wrapper {
        margin-top: 45px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;

        .entry {
            width: calc((608vw / 14.4));
            height: calc((434vw / 14.4));
            display: flex;
            align-items: center;

            &:nth-child(1) {
                margin-left: 80px;
            }

            &:nth-child(2) {
                width: calc((701vw / 14.4));
            }
        }
    }
}

.sub-highlight {
    position: relative;
    margin-top: 130px;
    padding: 0 119px;
    width: 100%;
    display: flex;
    align-content: center;

    .entry {
        width: calc((603vw / 14.4));

        &:nth-child(1) {
            p {
                margin-bottom: 110px;
            }
        }

        .visual {
            width: calc((603vw / 14.4));
            height: calc((963vw / 14.4));
            margin-left: auto;
            margin-right: auto;
        }

        &:nth-child(2) {
            width: calc((396vw / 14.4));
            margin-left: calc((119vw / 14.4));
            margin-top: -58px;

            .visual {
                width: calc((396vw / 14.4));
                height: calc((510vw / 14.4));
            }

            p {
                margin: 120px 0 0 0;
            }
        }
    }

    p {
        font-family: futura-pt, 'Noto Sans KR';
        line-height: 23px;
        font-size: 18px;
        font-weight: 100;
        max-width: 400px;
        margin: auto;
    }

    .visual {
        width: 100%;
    }

    .visual-bag {
        position: absolute;
        bottom: 0;
        right: calc((203vw / 14.4));
        width: calc((424vw / 14.4));
        height: calc((354vw / 14.4));
    }
}

.main-desc {
    font-family: futura-pt;
    margin: 100px auto auto auto;
    padding: 0 120px;
    max-width: 1190px;
    line-height: 52px;
    font-size: 40px;
    text-align: center;
    color: #2A3642;
}

.main-visual {
    position: relative;
}

.footer {
    margin: 100px 0 0 0;
    background: url('../../public/img/cases/bulgari/footer_bulgari.jpg');
    background-size: cover;
    background-position: bottom;
    height: calc((598vw / 14.4));
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-family: futura-pt;
        max-width: 952px;
        line-height: 52px;
        font-size: 40px;
        color: #FFF;
        text-align: center;
    }
}

@media only screen and (max-width: 1024px) {
    .hero {
        height: 385px;
        position: relative;
        align-items: flex-end;

        hgroup {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            max-width: 90%;
            margin-bottom: 38px;

            h1 {
                line-height: 64px;
                font-size: 50px;
                margin-bottom: 0;
            }

            h2 {
                font-family: futura-pt, 'Noto Sans KR';
                margin-top: 15px;
                font-size: 16px;
                line-height: normal;
                font-weight: 100;
                text-transform: lowercase;
                letter-spacing: 0;
                text-shadow: none;

                &:first-letter {
                    text-transform: uppercase;
                }
            }

            h3 {
                margin-top: 20px;
                font-size: 20px;
                letter-spacing: 0.1em;
                line-height: normal;
                text-shadow: none;
            }
        }
    }

    .intro {
        div {
            max-width: 282px;
            margin: auto;
            padding-top: 45px;

            h3 {
                font-size: 12px;
            }

            h4 {
                font-size: 20px;
                margin-top: 18px;
            }

            p {
                margin-bottom: 75px;
            }
        }
    }

    .highlight {
        height: auto;

        > .entry {
            width: 100%;
            height: auto;
            margin-bottom: 10px;

            &:nth-child(1) {
                img {
                    transform: scale(1.1);
                    margin-left: calc(-17vw/3.2);
                }
            }
        }

        .wrapper {
            display: block;
            margin: 0;
            margin-top: 40px;

            .entry {
                height: auto;
                width: 90% !important;
                margin: auto !important;
                margin-bottom: 48px !important;
            }
        }
    }

    .sub-highlight {
        text-align: center;
        flex-wrap: wrap;
        padding: 0;
        margin-top: -40px;

        .entry {
            margin: auto !important;
            width: 90% !important;
            margin-bottom: 55px;

            &:nth-child(1) {
                order: 2;
            }

            &:nth-child(2) {
                order: 1;
                display: flex;
                flex-wrap: wrap;

                p {
                    order: 1;
                    margin: 0 auto 50px auto;
                }

                .visual {
                    order: 2;
                }
            }

            p {
                font-size: 18px;
                margin: 40px auto !important;
            }

            .visual {
                height: auto !important;
                width: auto !important;
            }
        }

        .visual-bag {
            position: relative;
            order: 3;
            text-align: center;
            width: 100%;
            height: auto;
            right: 10px;
            margin-top: 60px;

            img {
                margin: auto;
                width: calc(262vw/3.8);
            }
        }
    }

    .main-visual {
        img {
            height: auto;
            width: 100%;
        }
    }

    .main-desc {
        padding: 0;
        margin-top: 45px;
        max-width: 286px;
        line-height: 28px;
        font-size: 22px;
        text-align: center;
    }

    .footer {
        background-repeat: no-repeat;
        height: 320px;
        display: flex;
        margin-top: 60px;

        p {
            position: initial;
            max-width: 308px;
            margin: 0;
            text-align: center;
            font-size: 22px;
            line-height: 28px;
            width: 90%;
        }
    }
}
</style>
