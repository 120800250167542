import Vue from 'vue';
import NProgress from 'nprogress';
import VueI18n from 'vue-i18n';
import vueVimeoPlayer from 'vue-vimeo-player';
import infiniteScroll from 'vue-infinite-scroll';
import 'fullpage.js';
import 'mapbox-gl';
import 'paroller.js';
import 'instafeed.js';
import VueScrollReveal from 'vue-scroll-reveal';
import RouterView from './views/RouterView.vue';
import router from './router';
import store from './store';
import messages from './translations';

const $ = require('jquery');
window.jQuery = window.$ = $;

Vue.use(vueVimeoPlayer);
Vue.use(infiniteScroll);

Vue.use(VueScrollReveal, {
  duration: 700,
  class: 'v-scroll-reveal',
  scale: 1,
  distance: '0',
  mobile: true,
});

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(RouterView),
}).$mount('#app');
// set hydrating to true to enable client-side handover of prerendered templates
Vue.http.interceptors.push((request, next) => {
  NProgress.configure({ easing: 'ease', speed: 500 });
  NProgress.configure({ showSpinner: false });
  NProgress.start();
  next(() => {
    NProgress.done();
  });
});
