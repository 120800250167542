<template>
  <div>
    <LoadingScreen v-if="this.isLoading"></LoadingScreen>
    <div id="content" :attr-sub="$t('brandtech')">
      <div class="wrapper" v-if="this.hero.background">
        <section
          class="title"
          :style="{ 'background-image': 'url(' + this.hero.background + ')' }"
        >
          <hgroup>
            <h1 v-html="hero.title"></h1>
            <h2 v-html="hero.subtitle"></h2>
          </hgroup>
          <!-- <div class="separator">
                <span></span>
                <span class="black"></span>
          </div>-->
        </section>

        <section id="brandtech-video" class="video-player" v-scroll-reveal>
          <div class="video">
            <vimeo-player
              ref="player"
              :video-id="this.videoID"
              :player-width="1200"
              :options="{ responsive: true, title: 0, byline: 0, portrait: 0 }"
              @play.once="onPlay"
              @ended="onEnded"
            ></vimeo-player>
          </div>
        </section>

        <section class="intro">
          <div>
            <h3 v-html="introduction.title" v-scroll-reveal></h3>
            <p v-html="introduction.content" v-scroll-reveal></p>
          </div>
        </section>

        <template v-for="(point, itemObjKey) in this.key_points">
          <section
            :key="itemObjKey"
            class="expert desktop"
            :style="{ 'background-image': 'url(' + point.background + ')' }"
          >
            <div class="wrapper">
              <h2 v-html="point.title"></h2>
              <p v-html="point.content"></p>
            </div>
          </section>

          <section
            class="expert mobile"
            :style="{
              'background-image':
                'url(' +
                point.background
                  .replace('.png', '_mob.png')
                  .replace('.jpg', '_mob.jpg')
                  .replace('.gif', '_mob.gif') +
                ')',
            }"
            :key="'mobile-' + itemObjKey"
          >
            <div class="wrapper">
              <h2 v-html="point.title"></h2>
              <p v-html="point.content"></p>
            </div>
          </section>
        </template>

        <section class="awardsSection" v-scroll-reveal>
          <h2>{{ $t('awards') }}</h2>
          <div class="wrapper">
            <div
              class="awards left"
              :style="{ 'background-image': 'url(' + awards.background + ')' }"
              v-if="awards.background"
            ></div>
            <div class="awards" v-scroll-reveal>
              <template v-for="award in awards.award">
                <div class="entry" :key="award.id">
                  <h3 v-if="award.year">{{ award.year }}</h3>
                  <p v-html="award.description"></p>
                  <span v-html="award.name"></span>
                  <hr />
                </div>
                <div class="mobile-separator" :key="award.id">
                  <hr class="white" />
                  <hr />
                </div>
              </template>
            </div>
          </div>
        </section>

        <section class="clients" v-scroll-reveal>
          <h2>{{ $t('our_clients') }}</h2>
          <div class="logos">
            <img
              src="../../public/img/clients_logo.svg"
              alt="clients"
              class="desktop"
            />
            <img
              src="../../public/img/clients_logo_mobile.svg"
              alt="clients"
              class="mobile"
            />
          </div>
          <h5>{{ $t('and_many_more') }}</h5>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import JobsService from '../services/JobsService';
import PageService from '../services/PageService';

import { mapGetters } from 'vuex';

const LoadingScreen = () =>
  import(
    /* webpackChunkName: "below-fold" */ '../components/LoadingScreen.vue'
  );

export default {
  components: {
    LoadingScreen,
  },

  data() {
    return {
      isLoading: true,
      hero: {},
      introduction: {},
      key_points: {},
      job_opening: {},
      jobs: {},
      awards: {},
      videoID: '',
    };
  },

  created() {
    this.fetchItem(this.$i18n.locale);
    this.updateVideo(this.$i18n.locale);
  },

  methods: {
    fetchItem(lang) {
      return PageService.get(lang, 'who-we-are')
        .then((result) => {
          this.item = result.data[0];
          this.hero = this.item.acf.hero;
          this.introduction = this.item.acf.introduction;
          this.key_points = this.item.acf.introduction.key_points;
          this.job_opening = this.item.acf.job_opening;
          this.awards = this.item.acf.awards;
          this.brandtech_video = this.item.acf.brandtech_video;

          Promise.all(
            this.job_opening.map((job) =>
              JobsService.get(job.ID).then((result) => {
                return result.body;
              })
            )
          ).then((data) => {
            this.jobs = data;
            setTimeout(() => {
              this.$parent.updateSub();
              this.isLoading = false;
              $('.title').paroller({
                factor: 0.3,
                type: 'background',
                direction: 'vertical',
              });
            }, 500);
          });
        })
        .catch(() => {
          this.error = true;
        });
    },
    updateVideo(lang) {
      switch (lang) {
        case 'ko':
          this.videoID = '402776921';
          break;
        case 'ja':
          this.videoID = '402776448';
          break;
        default:
          this.videoID = '402775635';
      }
    },
    stopVideo() {
      this.$refs.player.stop();
    },
    onPlay() {
      this.$ga.event('Video', 'play', 'whoweare_page_video');
    },
    onEnded() {
      this.$ga.event('Video', 'end', 'landing_page_video');
    },
  },

  computed: {
    ...mapGetters({
      items: 'allPosts',
      filteredItems: 'filteredPosts',
    }),
  },

  mounted: function () {
    if ($('body').css('overflow') == 'hidden') {
      $('#fullpage').fullpage.destroy('all');
    }

    $('.subtitle:first').show();
    $('.subtitle:first').addClass('white');

    $('header').removeClass();
    $('header').removeClass('black');
    $('header:first').addClass('fixed');

    setTimeout(() => {
      $('.jobs-view-all').on('click', function (e) {
        e.preventDefault();
        $('.jobs-wrapper').addClass('open');
        $('.jobs-view-all').hide();
      });
    }, 2000);

    var Utils = this.$parent.scrollReveal();
    $(document).on('scroll', () => {
      $('.expert').each((i, e) => {
        const isElementInView = Utils.isElementInView($(e), false);
        if (isElementInView) {
          $(e).addClass('active');
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  display: none;
}

.desktop {
  display: block;
}

.subtitle {
  z-index: 999;
  position: absolute;
  color: #fff;
  transform: rotate(-90deg);
  position: fixed;
  top: 180px;
  left: 5px;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0.3em;
  text-transform: uppercase;

  span {
    display: inline-block;
    width: 47px;
    height: 1px;
    background-size: 1px;
    background-color: #fff;
    margin-top: 6px;
    margin-left: 10px;
    float: right;
  }
}

.title {
  background-image: url('../../public/img/bgd_who_we_are.jpg');
  background-size: cover;
  background-position: center center;
  height: 100vh;
  position: relative;
  display: flex;
  padding-top: calc((200vw / 14.4));
  min-height: 700px;

  hgroup {
    margin-left: calc((171vw / 14.4));
    max-width: calc((871vw / 14.4));
  }

  h1 {
    font-family: futura-pt, 'Noto Sans KR';
    color: #fff;
    font-size: calc((90vw / 14.4));
    line-height: calc((116vw / 14.4));
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0;
  }

  h2 {
    font-family: futura-pt, 'Noto Sans KR';
    color: #fff;
    font-size: calc((40vw / 14.4));
    line-height: calc((51vw / 14.4));
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0;
  }

  .separator {
    position: absolute;
    bottom: 0;
    width: 1px;
    height: 60px;
    left: 0;
    right: 0;
    margin: auto;

    span {
      float: right;
      position: relative;
      top: 30px;
      width: 1px;
      height: 30px;
      background: #fff;
    }

    span.black {
      background: #2a3642;
    }
  }
}

.video-player {
  padding: calc((67vw / 14.4)) calc((105vw / 14.4)) 0;

  .video {
    width: 100%;
    height: auto;
    cursor: pointer;
    margin: auto;
    text-align: center;

    iframe {
      margin: auto;
    }
  }
}

.intro {
  div {
    background: #fff;
    text-align: center;
    width: 100%;
    padding-top: 62px;

    h3 {
      font-family: futura-pt;
      line-height: 30px;
      font-size: 20px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      margin: auto;
      font-weight: normal;
      color: #2a3642;
    }

    p {
      font-family: futura-pt, 'Noto Sans KR';
      max-width: 577px;
      font-size: 18px;
      line-height: 23px;
      margin: 40px auto 110px auto;
      font-weight: 100;
    }
  }
}

.expert {
  background-image: url('../../public/img/bgd_expert.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: calc((1320vw / 14.4));
  height: calc((542vw / 14.4));
  padding-top: calc((224vw / 14.4));
  padding-left: calc((216vw / 14.4));
  margin-bottom: calc((68vw / 14.4));
  transform: translateX(-100%);
  transition: all 900ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;

  &:nth-child(4n + 3) {
    float: left;
  }

  &:nth-child(4n) {
    float: left;
  }

  &:nth-child(4n + 1) {
    float: right;
    transform: translateX(100%);
    padding-left: calc((654vw / 14.4));
  }

  &:nth-child(4n + 2) {
    float: right;
    transform: translateX(100%);
    padding-left: calc((654vw / 14.4));
  }

  &.active {
    transform: translateX(0);
    opacity: 1;

    .wrapper {
      opacity: 1;
    }
  }

  .wrapper {
    opacity: 0;
    transition: opacity 900ms linear;
    transition-delay: 0.5s;
    max-width: calc((485vw / 14.4));
  }

  h2 {
    font-family: futura-pt;
    color: #fff;
    line-height: normal;
    font-size: calc((45vw / 14.4));
    margin-bottom: calc((25vw / 14.4));
    font-weight: normal;
  }

  p {
    font-family: futura-pt, 'Noto Sans KR';
    color: #fff;
    line-height: calc((23vw / 14.4));
    font-size: calc((18vw / 14.4));
    font-weight: 100;
  }
}

@media only screen and (min-width: 1930px) {
  .expert {
    padding-top: calc((225vw / 14.4));
  }
}

.experience {
  float: right;
  background-image: url('../../public/img/bgd_experience.jpg');
  padding-left: calc((540vw / 14.4));
  transform: translateX(100%);

  h2 {
    max-width: inherit;
  }

  p {
    max-width: 468px;
  }
}

.jobs-opening {
  clear: both;
  text-align: center;

  h2 {
    font-family: futura-pt;
    font-size: 20px;
    line-height: 23px;
    color: #2a3642;
    margin-bottom: 83px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
  }

  .jobs-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;
    transition: all 0.3s ease-in-out;

    &.open {
      max-height: 1000px;
    }
  }

  .jobs {
    width: calc((1200vw / 14.4));
    margin: auto;
  }

  .jobs-view-all {
    font-family: futura-pt;
    float: right;
    font-size: 12px;
    text-align: right;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #2a3642;
    margin-top: 63px;

    svg {
      float: right;
      margin-top: -10px;
      margin-left: 10px;
    }
  }

  .entry {
    position: relative;
    font-family: futura-pt;
    text-align: left;
    width: calc((570vw / 14.4));
    background: #f2f2f2;
    transition: background 0.3s cubic-bezier(0.22, 0.44, 0, 1);
    margin-bottom: 38px;
    cursor: pointer;

    a {
      padding: 40px 70px 40px 35px;
      display: block;
      position: relative;
      display: flex;

      span {
        font-family: futura-pt;
        font-size: 16px;
        font-weight: 500;
        margin-left: 10px;
        color: #e33a55;
        position: absolute;
        right: 65px;
        text-transform: uppercase;
      }
    }

    &:hover {
      background-color: #c43e51;

      h3,
      a,
      span {
        color: #fff;
      }

      svg {
        transform: translateX(10px);

        use {
          fill: #fff;
        }
      }
    }

    a {
      color: #000000;
      transition: color 0.3s cubic-bezier(0.22, 0.44, 0, 1);
    }

    h3 {
      font-size: 16px;
      transition: color 0.3s cubic-bezier(0.22, 0.44, 0, 1);
      // margin-bottom: 12px
    }

    p {
      font-family: futura-pt, 'Noto Sans KR';
      line-height: 21px;
      font-size: 16px;
      font-weight: 100;
      max-width: 377px;
      display: none;
    }

    svg {
      position: absolute;
      right: 25px;
      top: 40%;
      transition: transform 0.3s cubic-bezier(0.22, 0.44, 0, 1);

      use {
        transition: fill 0.3s linear;
      }
    }
  }

  .view-more {
    width: 100%;
    text-align: right;
    line-height: 32px;
    vertical-align: middle;
    display: flex;
    justify-content: flex-end;

    a {
      font-family: futura-pt;
      color: #2a3642;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      font-size: 12px;
    }

    svg {
      margin-left: 20px;
      float: right;
    }
  }
}

.awardsSection {
  position: relative;
  clear: both;

  h2 {
    text-align: center;
    font-family: futura-pt;
    font-size: 20px;
    line-height: 23px;
    color: #2a3642;
    margin-bottom: 83px;
    padding-top: calc((55vw / 14.4));
    letter-spacing: 0.3em;
    text-transform: uppercase;
  }

  .wrapper {
    position: relative;
    height: 950px;
  }
}

.awards {
  position: absolute;
  background: #fff;
  vertical-align: middle;
  width: 50%;
  display: inline-block;
  height: 100%;
  right: 0;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .mobile-separator {
    display: none;
  }

  hr {
    width: calc((50vw / 14.4));
    transform: rotate(-90deg);
    height: 1px;
    background: #000;
    border: none;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -72px;
  }

  .entry {
    color: #3b434d;
    // height: 33.33%;
    max-width: 542px;
    margin: 75px auto;
    position: relative;

    &:nth-child(5) {
      hr {
        display: none;
      }
    }
  }

  h3 {
    font-family: futura-pt;
    color: #3b434d;
    font-size: 60px;
    line-height: 78px;
    margin: 0 0 9px 0;
    letter-spacing: 0.1em;
    font-weight: normal;
  }

  p {
    font-family: futura-pt;
    color: #3b434d;
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 10px 0;
  }

  span {
    font-family: futura-pt, 'Noto Sans KR';
    color: #3b434d;
    font-size: 16px;
    line-height: 21px;
    font-weight: 100;
    background: #fff;
    display: block;
    z-index: 2;
    position: relative;
    padding-bottom: 10px;
  }
}

.awards.left {
  left: 0;
  right: inherit;
  background-size: cover;
  background-repeat: no-repeat;
}

.we-are {
  padding-top: 132px;

  h2 {
    font-family: futura-pt;
    font-size: 20px;
    line-height: 23px;
    color: #2a3642;
    margin-bottom: 83px;
    text-align: center;
    letter-spacing: 0.3em;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;
    margin: auto;
    max-width: 1120px;
    margin: auto;

    .entry {
      width: 500px;
      margin-bottom: 167px;
      display: flex;
    }

    .content {
      max-width: 308px;
      margin-left: 45px;

      p {
        font-family: futura-pt, 'Noto Sans KR';
        font-size: 40px;
        font-weight: 100;
        color: #2a3642;
      }

      span {
        font-family: futura-pt;
        color: #c43e51;
        font-size: 60px;

        span {
          color: #2a3642;
          font-size: 40px;
          font-family: futura-pt, 'Noto Sans KR';
          font-weight: 100;
        }
      }
    }
  }
}

.clients {
  padding-top: calc((123vw / 14.4));
  .logos {
    background-color: #f7f7f7;
    padding-bottom: 100px;
  }

  h2 {
    font-family: futura-pt;
    font-size: 20px;
    line-height: 23px;
    color: #2a3642;
    margin-bottom: 83px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
  }

  h4 {
    font-family: futura-pt, 'Noto Sans KR';
    line-height: normal;
    font-size: 144px;
    text-align: center;
    color: #c43e51;
    font-weight: 700;
  }

  h5 {
    margin-top: -80px;
    font-family: futura-pt;
    font-size: 20px;
    line-height: 23px;
    color: #2a3642;
    margin-bottom: 83px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
  }
}

.ja {
  .intro {
    div {
      h3 {
        max-width: 400px;
      }
    }
  }

  .expert {
    h2 {
      max-width: 800px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .subtitle {
    left: -40px;
    top: 170px;
  }

  .title {
    min-height: auto;
    height: 50vh;
    padding-top: calc((100vw / 3.2));

    hgroup {
      left: 35px;
      max-width: 90%;

      h1 {
        font-size: 30px;
        line-height: normal;
      }

      h2 {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 20px;
      }
    }

    div {
      h3 {
        font-size: 12px;
        line-height: 18px;
        max-width: 246px;
      }

      p {
        font-size: 12px;
        margin: 20px auto;
        max-width: 330px;
      }
    }
  }

  .video-player {
    margin-top: calc((35vw / 3.2));
  }

  .intro {
    max-width: 90%;
    margin: auto;

    div {
      padding-top: calc((35vw / 3.2));

      h3 {
        font-size: 12px;
        line-height: 18px;
        max-width: 246px;
      }

      p {
        margin-bottom: 77px;
        max-width: 330px;
      }
    }
  }

  .expert {
    height: calc(100vw * 1.128);
    min-height: 350px;
    width: 100%;
    padding: 50px 50px 60px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 40px;

    .wrapper {
      max-width: none;
    }

    &:nth-child(4n + 1) {
      padding: 50px 50px 60px;
    }

    &:nth-child(even) {
      height: calc(100vw * 1.128);
      min-height: 350px;
      width: 100%;
      padding: 50px 50px 60px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 40px;
    }

    &.desktop {
      display: none;
    }

    h2 {
      font-size: 23px;
    }

    p {
      font-size: 17px;
      line-height: normal;
    }
  }

  .jobs-opening {
    padding-top: 40px;

    h2 {
      font-size: 12px;
      margin-bottom: 20px;
    }

    .jobs {
      .entry {
        width: 90%;
        padding: 20px;
        margin-left: auto;
        margin-right: auto;

        a {
          padding: 0;

          span {
            display: none;
          }
        }

        svg {
          right: -13px;
        }
      }

      .view-more {
        justify-content: center;

        a {
          position: relative;
          padding-top: 40px;
        }

        svg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }

    .jobs-view-all {
      margin-top: 40px;
      float: none;
      display: flex;
      margin: auto;
      text-align: center;
      align-items: center;
      align-content: center;
      flex-direction: column-reverse;

      svg {
        margin-bottom: 10px;
      }
    }
  }

  .awardsSection {
    margin-top: 40px;

    h2 {
      display: block;
      margin-bottom: 40px;
    }

    .wrapper {
      height: 1070px;
    }
  }

  .awards {
    width: 100%;
    background: none;
    bottom: 0;
    margin: 0;
    padding: 0;
    justify-content: start;
    display: block;
    height: 1070px;

    .mobile-separator {
      position: relative;
      padding: 20px 0;
      margin-top: -20px;
      width: 100%;
      display: block;

      &:last-child {
        display: none;
      }

      hr {
        bottom: 10px;
        width: 20px;
      }

      .white {
        top: 0;
        background: #fff;
      }
    }

    &:last-child {
      div:nth-child(4) {
        hr {
          width: 40px;
        }
      }
    }

    .entry {
      max-width: 243px;
      flex-wrap: wrap;
      align-items: center;
      text-align: center;
      min-height: 335px;
      max-height: 335px;
      overflow: hidden;
      padding: 40px 0 0 0;

      h3,
      p,
      span {
        color: #fff;
        background: none;
        margin: auto;
      }

      h3 {
        font-size: 46px;
      }

      p {
        font-size: 18px;
        margin: 0 0 20px;
      }

      span {
        font-size: 16px;
      }

      hr {
        display: none;
      }

      &:nth-child(4) {
        display: none;
      }

      &:nth-child(5) {
        /* display: none; */
      }

      &:nth-child(6) {
        display: none;
      }

      &:nth-child(3),
      &:nth-child(5) {
        h3,
        p,
        span {
          color: #3b434d;
        }
      }
    }
  }

  .awards.left {
    top: 0;
    width: 100%;
    background-color: #fff;
    height: 50%;
    min-height: 335px;
    max-height: 335px;
    margin: 0;
  }

  .top {
    height: 100%;
    padding-top: 80px;
    text-align: center;
  }

  .clients {
    margin-top: -30px;
    z-index: 2;
    background: #fff;
    position: relative;
    .logos {
      background-color: transparent;
      padding-bottom: 0;
      padding-top: 20px;
    }

    h2 {
      font-size: 12px;
      margin-bottom: 20px;
    }

    img {
      max-width: 85%;
      margin: auto;
    }

    h5 {
      font-size: 12px;
      margin-bottom: 20px;
      margin-top: 30px;
    }
  }

  .we-are {
    padding: 10px;

    h2 {
      font-size: 12px;
      margin-bottom: 0;
    }

    .wrapper {
      margin: auto;
      max-width: auto;
      justify-content: center;

      .entry {
        width: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        &:nth-child(even) {
          .content {
            order: 1;
          }

          svg {
            order: 2;
          }
        }
      }

      .content {
        margin: 0;
        width: 50%;

        p {
          font-size: 26px;
          line-height: normal;
        }

        span {
          font-size: 38px;
        }
      }

      svg {
        transform: scale(0.65);
      }
    }
  }

  .ja,
  .ko {
    .clients {
      h2,
      h5 {
        font-size: 16px;
      }
    }

    .jobs-opening,
    .why-asiance {
      h2 {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .expert {
    padding: 40px 40px 45px 40px;

    &:nth-child(even) {
      padding: 40px 40px 45px 40px;
    }
  }
}

@media only screen and (max-width: 350px) {
  .expert {
    padding: 40px 40px 50px;

    &:nth-child(even) {
      padding: 40px 40px 50px;
    }

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
      line-height: normal;
    }
  }
}
</style>
